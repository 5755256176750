import React from "react";
import $ from  "jquery";


class SetColor extends React.Component{
    componentDidMount() {
        let hue = Math.random() * 255;
        let bg = 'hsl('+hue+', 76%, 13%)';
        let color = 'hsl('+hue+', 76%, 47%)';

        $('body').css('background-color', bg);
        $('.title').css('color', color);
        $('#title-img').css('background-color', color);
        $('#just-dont').css('color', color);
    }
    render() {
        return '';
    }
}


export default SetColor
