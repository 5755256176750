import React from "react";

class SetTimeTitle extends React.Component{
    constructor() {
        super();
        this.setTime = this.setTime.bind(this);
    }
    componentDidMount() {
        this.interval = setInterval(() => this.setState({ time: Date.now() }), 1000);
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }

    setTime(){
        var startDate = new Date();
        var endDate = new Date(1623495969 * 1000);
        var seconds = (endDate.getTime() - startDate.getTime()) / 1000;
        return(Math.round(seconds));
    }

    render() {
        document.title = this.setTime();
        return (this.setTime());
    }
}

export default SetTimeTitle;
