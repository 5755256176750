import { Router, Route, Switch } from "react-router";
import Home from "./Home";
import GuessWhat from "./Components/GuessWhat";

function App() {
    return (
        <Home/>
    );
}

export default App;
