import React from "react";

export default function Title() {
    return (
        <>
            <div className="title">
                <div id="title-char-1">t</div>
                <div id="title-char-2">i</div>
                <div id="title-char-3">i</div>
                <div id="title-char-4">m</div>
                <a href={"https://github.com/timothebot"} target={"_blank"} id="title-img"/>
                <div id="title-version">.v1</div>
            </div>
        </>
    )
}
