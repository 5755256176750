import './Style/main.scss';
import Title from "./Components/Title";
import dont from "./Media/dont.mp3";
import $ from "jquery";
import SetColor from "./Components/SetColor";
import SetTimeTitle from "./Components/SetTimeTitle";

let isPlaying = false;

function Home() {
    const playAudio = () => {
        if(isPlaying)
            return;

        let audio = new Audio(dont);
        audio.play();
        audio.volume = 0.4;
        $('body').css('background','url("/hehe.gif") no-repeat');
        $('body').css('background-size','cover');
        $('#just-dont > p').text('( ͡° ͜ʖ ͡°)');
        $('#just-dont').css('opacity', '1');
        isPlaying = true;
    }
    return (
        <>
            <Title/>
            <div id="just-dont">
                <p onClick={playAudio}>don't click here</p>
            </div><SetColor/>
        </>
    );
}

export default Home;
